
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { capitalize, isNumber } from 'lodash-es'
import { TranslateResult } from 'vue-i18n'
import { mdiRedoVariant } from '@mdi/js'

import { useNotify } from '@/store'

import { useGetAssignments } from '@/api/assignment'
import { useGetContracts } from '@/api/contract'
import { useGetWorkingHours, useUpdateWorkingHours } from '@/api/workingHours'

import { convertCommaIntoDotInput, convertDotIntoCommaInput } from '@/utils/convertInput'
import { convertToEuro } from '@/utils/convertCurrency'
import { convertToPercentage } from '@/utils/convertPercentage'
import { isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { AssignmentOutput, AssignmentId } from '@/api/types/assignment'
import { WorkingHours, WorkingHoursId } from '@/api/types/workingHours'
import { PersonOutput } from '@/api/types/person'
import { BudgetDataLeft, BudgetDataRight } from '@/views/persons/views/personProfile/types'
import { isOfType } from '@/utils/types/isOfType'
import { Rights } from '@/api/types/right'
import { ContractOutput } from '@/api/types/contract'

interface Cell {
  key: string
  type: CellType
  value: string | number
  hasBeenEditedManually: boolean
  workingHourId: WorkingHoursId | null
  isPreviousMonth: boolean
  isAssignmentStart: boolean
}

enum CellType {
  NAME_CELL,
  DATA_CELL,
}

interface CellCoordinate {
  x: number | null
  y: number | null
}

type ColumnHeader = { key: string; idx: number; value: TranslateResult }
type RowTitle = ColumnHeader

interface SelectedCell {
  cell: Cell
  type: SelectedCellType
}

enum SelectedCellType {
  PLANNED = 'planned',
  RECORDED = 'recorded',
}

export default defineComponent({
  name: 'Budget',
  components: {
    CommonNumberInput: () => import('@/components/common/CommonNumberInput.vue'),
    CommonAutocomplete: () => import('@/components/common/CommonAutocomplete.vue'),
    BudgetInfoBox: () =>
      import('@/views/persons/views/personProfile/components/lowerPersonProfile/components/BudgetInfoBox.vue'),
  },
  props: {
    person: {
      type: Object as PropType<PersonOutput | null>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const SUM_ROW_IDX_PLANNED_HOURS = '0-12'
    const SUM_ROW_IDX_RECORDED_HOURS = '1-12'

    const COLUMN_HEADERS: readonly ColumnHeader[] = [
      {
        key: 'month',
        idx: 0,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.month'),
      },
      {
        key: 'jan',
        idx: 1,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.january'),
      },
      {
        key: 'feb',
        idx: 2,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.february'),
      },
      {
        key: 'mar',
        idx: 3,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.march'),
      },
      {
        key: 'apr',
        idx: 4,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.april'),
      },
      {
        key: 'may',
        idx: 5,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.may'),
      },
      {
        key: 'jun',
        idx: 6,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.june'),
      },
      {
        key: 'jul',
        idx: 7,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.july'),
      },
      {
        key: 'aug',
        idx: 8,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.august'),
      },
      {
        key: 'sep',
        idx: 9,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.september'),
      },
      {
        key: 'okt',
        idx: 10,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.october'),
      },
      {
        key: 'nov',
        idx: 11,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.november'),
      },
      {
        key: 'dec',
        idx: 12,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.december'),
      },
      {
        key: 'sum',
        idx: 13,
        value: root.$t('persons.profile.tabMenu.budget.colHeaders.sum'),
      },
    ]

    const ROW_TITLES: readonly RowTitle[] = [
      {
        key: 'hoursPlanned',
        idx: 0,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.hoursPlanned'),
      },
      {
        key: 'hoursRecorded',
        idx: 1,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.hoursRecorded'),
      },
      {
        key: 'usagePercentage',
        idx: 2,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.usagePercentage'),
      },
      {
        key: 'hourlyRate',
        idx: 3,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.hourlyRate'),
      },
      {
        key: 'budget',
        idx: 4,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.budget'),
      },
      {
        key: 'calculated',
        idx: 4,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.calculated'),
      },
      {
        key: 'delta',
        idx: 5,
        value: root.$t('persons.profile.tabMenu.budget.rowTitles.delta'),
      },
    ]

    const { exec: getAssignments, data: assignments } = useGetAssignments()

    const { exec: getWorkingHours, data: workingHours } = useGetWorkingHours()

    const { exec: getContracts, data: contracts } = useGetContracts()

    const selectedYear = ref<number | null>(new Date().getUTCFullYear())

    const maxAvailableYear = computed(() => {
      const startDate = navigator.userAgent.includes('Firefox') ? new Date('1900-01-01') : new Date('01-01-1900')

      const year = assignments.value.reduce((prevDate, curr) => {
        if (new Date(curr.end) > new Date(prevDate)) {
          prevDate = new Date(curr.end)
        }
        return prevDate
      }, startDate)

      return year.getUTCFullYear()
    })

    const minAvailableYear = computed(() => {
      const year = assignments.value.reduce((prevDate, curr) => {
        if (new Date(curr.start) < new Date(prevDate)) {
          prevDate = new Date(curr.start)
        }
        return prevDate
      }, new Date())

      return year.getUTCFullYear()
    })

    const availableYears = computed(() => {
      const years: number[] = []

      for (let i = 0; i <= maxAvailableYear.value - minAvailableYear.value; i++) {
        years.push(maxAvailableYear.value - i)
      }

      return years
    })

    const selectedAssignmentId = ref<AssignmentId>(-1)

    async function fetchData(): Promise<void> {
      await getAssignments({ params: { personIds: root.$route.params.id, size: 9999 } })

      const assignmentIds = assignments.value.map((assignment) => assignment.id)

      await getWorkingHours({
        params: {
          personIds: root.$route.params.id,
          assignmentIds: String(assignmentIds),
          size: 9999,
        },
      })

      await getContracts({ params: { personIds: root.$route.params.id, size: 9999 } })

      createRows()
    }
    fetchData()

    function isAssignmentSelected(): boolean {
      return selectedAssignmentId.value >= 0
    }

    const rowData = ref<Cell[]>([])

    function resetRowData(): void {
      rowData.value = []
    }

    function getYearOutOfProperty(string: string): number | null {
      if (typeof string !== 'string') return null

      return Number(string.split('-')[0])
    }

    function getMonthOutOfProperty(string: string): number | null {
      if (typeof string !== 'string') return null

      return Number(string.split('-')[1])
    }

    function getWorkingHoursFilteredByYear(year: number): WorkingHours[] {
      return workingHours.value.filter((hour) => getYearOutOfProperty(hour.month) === year)
    }

    function getWorkingHoursFilteredByYearAndAssignment(year: number, assignmentId: AssignmentId): WorkingHours[] {
      return workingHours.value.filter(
        (hour) => getYearOutOfProperty(hour.month) === year && hour.assignment?.id === assignmentId
      )
    }

    function getAssignmentBySelectedAssignmentId(): AssignmentOutput | null {
      return assignments.value.find((assignment) => assignment.id === selectedAssignmentId.value) ?? null
    }

    function getContractsOfSelectedAssignment(): ContractOutput[] {
      return (
        getAssignmentBySelectedAssignmentId()?.contracts.map((assignmentContract) =>
          contracts.value.find((contract) => contract.id === assignmentContract.id)
        ) ?? []
      ).filter((contract): contract is ContractOutput => typeof contract !== undefined)
    }

    function isInContractTime(selectedContract: ContractOutput | undefined, monthIdx: number) {
      if (isOfType<ContractOutput>(selectedContract, 'start')) {
        const [startYear, startMonth] = selectedContract?.start.split('-')
        const [endYear, endMonth] = selectedContract?.end.split('-')

        if (startYear === endYear) {
          if (monthIdx >= Number(startMonth) && monthIdx <= Number(endMonth)) {
            return true
          } else {
            return false
          }
        } else if (
          (selectedYear.value &&
            ((selectedYear.value === Number(startYear) && monthIdx >= Number(startMonth)) ||
              (selectedYear.value === Number(endYear) && monthIdx <= Number(endMonth)))) ||
          (selectedYear.value !== Number(startYear) && selectedYear.value !== Number(endYear))
        ) {
          return true
        } else {
          return false
        }
      }
    }

    function getContractForMonth(contracts: ContractOutput[], monthIdx: number): ContractOutput | undefined {
      return contracts.find((contract) => {
        if (!contract?.start || !contract?.end) return false

        return isInContractTime(contract, monthIdx)
      })
    }

    function getPlannedHoursInCell(colIdx: number): number {
      return Number(rowData.value.find((cell) => cell.key === '0-' + colIdx)?.value)
    }

    function getRecordedHoursInCell(colIdx: number): number {
      return Number(rowData.value.find((cell) => cell.key === '1-' + colIdx)?.value)
    }

    function getBudgetInCell(colIdx): number {
      return Number(rowData.value.find((cell) => cell.key === '4-' + colIdx)?.value)
    }

    function getCalculatedBudgetInCell(colIdx): number {
      return Number(rowData.value.find((cell) => cell.key === '5-' + colIdx)?.value)
    }

    function calculateCellValue(
      workingHoursFilteredByYear: WorkingHours[],
      rowTitle: string,
      colIdx: number,
      rowIdx: number
    ): string | number {
      const SUM_COLUMN_IDX = 12
      const INITIAL_REDUCER_VALUE = 0
      const COL_IDX_AS_MONTH_NUMBER = colIdx + 1

      const plannedHours = getPlannedHoursInCell(colIdx)
      const recordedHours = getRecordedHoursInCell(colIdx)
      const budget = getBudgetInCell(colIdx)
      const calculated = getCalculatedBudgetInCell(colIdx)

      const contractsOfSelectedAssignment = getContractsOfSelectedAssignment()

      const contractForMonth = getContractForMonth(contractsOfSelectedAssignment, COL_IDX_AS_MONTH_NUMBER)

      const hourlyRate =
        contractForMonth?.hourlyRate &&
        !isNaN(plannedHours) &&
        isInContractTime(contractForMonth, COL_IDX_AS_MONTH_NUMBER)
          ? contractForMonth.hourlyRate
          : 0

      if (colIdx === SUM_COLUMN_IDX) {
        const rowCellData = rowData.value.filter((cell) => cell.key.startsWith(String(rowIdx)))

        switch (rowTitle) {
          case 'hourlyRate': {
            let countCellsWithValue = 0
            const sum = rowCellData.reduce((prev, curr) => {
              const value = Number(curr.value)

              if (isNaN(value)) {
                return (prev += 0)
              } else {
                countCellsWithValue++

                return (prev += value)
              }
            }, INITIAL_REDUCER_VALUE)

            return isNaN(sum / countCellsWithValue) ? '0,0' : Number(sum / countCellsWithValue).toFixed(2)
          }
          case 'usagePercentage': {
            if (selectedAssignmentId.value && isOfType<PersonOutput>(props.person, 'targetPerformanceComparison')) {
              let countCellsWithValue = 0
              const sum = rowCellData.reduce((prev, curr) => {
                const value = Number(curr.value)

                if (isNaN(value)) {
                  return (prev += 0)
                } else {
                  countCellsWithValue++

                  return (prev += value)
                }
              }, INITIAL_REDUCER_VALUE)

              return isNaN(sum / countCellsWithValue) ? '0,0' : Number(sum / countCellsWithValue).toFixed(2)
            } else {
              return 0
            }
          }
          default: {
            const cellData = rowCellData.reduce((prev, curr) => {
              const value = Number(curr.value)

              return (prev += isNaN(value) ? 0 : value)
            }, INITIAL_REDUCER_VALUE)

            return isNaN(cellData) || cellData === 0 ? '0,0' : cellData
          }
        }
      }

      switch (rowTitle) {
        case 'hoursPlanned': {
          if (!isAssignmentSelected()) {
            const workingHours = workingHoursFilteredByYear.filter(
              (hour) => (getMonthOutOfProperty(hour.month) ?? 0) - 1 === colIdx
            )

            return workingHours.reduce((prev, curr) => {
              return (prev += Number(curr.hoursPlanned))
            }, INITIAL_REDUCER_VALUE)
          } else {
            const workingHour = workingHoursFilteredByYear.find((hour) => {
              return (getMonthOutOfProperty(hour.month) ?? 0) - 1 === colIdx
            })?.[rowTitle]

            return isNumber(workingHour) && isInContractTime(contractForMonth, COL_IDX_AS_MONTH_NUMBER)
              ? workingHour
              : '-'
          }
        }
        case 'hoursRecorded': {
          if (!isAssignmentSelected()) {
            const workingHours = workingHoursFilteredByYear.filter(
              (hour) => (getMonthOutOfProperty(hour.month) ?? 0) - 1 === colIdx
            )
            return workingHours.reduce((prev, curr) => (prev += Number(curr.hoursRecorded)), INITIAL_REDUCER_VALUE)
          }

          const workingHour = workingHoursFilteredByYear.find((hour) => {
            return (getMonthOutOfProperty(hour.month) ?? 0) - 1 === colIdx
          })?.[rowTitle]

          return isNumber(workingHour) && isInContractTime(contractForMonth, COL_IDX_AS_MONTH_NUMBER)
            ? workingHour
            : '-'
        }
        case 'usagePercentage': {
          const usagePercentage = recordedHours / plannedHours

          return isNaN(usagePercentage) || !isInContractTime(contractForMonth, COL_IDX_AS_MONTH_NUMBER)
            ? '-'
            : usagePercentage
        }
        case 'hourlyRate': {
          return hourlyRate && !isNaN(plannedHours) && isInContractTime(contractForMonth, COL_IDX_AS_MONTH_NUMBER)
            ? hourlyRate
            : '-'
        }
        case 'budget': {
          const budget = hourlyRate * plannedHours
          return isNaN(budget) ? '-' : Number(budget.toFixed(2))
        }
        case 'calculated': {
          const calculated = hourlyRate * recordedHours
          return isNaN(calculated) || calculated === 0 ? '-' : Number(calculated.toFixed(2))
        }
        case 'delta': {
          const delta = budget - calculated
          return isNaN(delta) || delta === 0 ? '-' : Number(delta.toFixed(2))
        }
        default:
          return '-'
      }
    }

    function createNameColumn(rowIdx: number): void {
      rowData.value.push({
        key: ROW_TITLES[rowIdx].key, // i.e. 'hoursPlanned'
        value: ROW_TITLES[rowIdx].value as string, // i.e. 'Plan h'
        type: CellType.NAME_CELL,
        hasBeenEditedManually: false,
        workingHourId: null,
        isPreviousMonth: false,
        isAssignmentStart: false,
      })
    }

    function isPlannedHoursRow(idx: number): boolean {
      return idx === 0
    }

    function getWorkingHoursOfMonthByColIdx(workingHours: WorkingHours[], colIdx: number): WorkingHours | null {
      return (
        workingHours.find((hour) => {
          const [year, month] = hour.month.split('-')

          if (Number(year) === selectedYear.value && Number(month) === colIdx + 1) {
            return hour
          }
        }) ?? null
      )
    }

    function getAssignmentBySelectedYearAndColIdx(colIdx: number): AssignmentOutput | null {
      return (
        assignments.value.find((assignment) => {
          const [year, month] = assignment.start.split('-')

          if (Number(year) === selectedYear.value && Number(month) === colIdx + 1) {
            return assignment
          }
        }) ?? null
      )
    }

    function getActiveAssignmentsBySelectedYear(): AssignmentOutput[] {
      return assignments.value.filter((assignment) => {
        const [startYear] = assignment.start.split('-')
        const [endYear] = assignment.end.split('-')

        if (selectedYear.value && selectedYear.value >= Number(startYear) && selectedYear.value <= Number(endYear)) {
          return assignment
        }
      })
    }

    function createRows(): void {
      resetRowData()

      const filteredWorkingHours = isAssignmentSelected()
        ? getWorkingHoursFilteredByYearAndAssignment(Number(selectedYear.value), selectedAssignmentId.value)
        : getWorkingHoursFilteredByYear(Number(selectedYear.value))

      ROW_TITLES.forEach((rowTitle, rowIdx) => {
        createNameColumn(rowIdx)

        for (let colIdx = 0; colIdx < COLUMN_HEADERS.length - 1; colIdx++) {
          const workingHour = getWorkingHoursOfMonthByColIdx(filteredWorkingHours, colIdx)

          const assignment = getAssignmentBySelectedYearAndColIdx(colIdx)

          rowData.value.push({
            key: rowIdx + '-' + colIdx,
            value: calculateCellValue(filteredWorkingHours, rowTitle.key, colIdx, rowIdx),
            type: CellType.DATA_CELL,
            hasBeenEditedManually: (workingHour?.hoursPlannedEditedManually && isPlannedHoursRow(rowIdx)) ?? false,
            workingHourId: workingHour?.id ?? null,
            isPreviousMonth:
              COLUMN_HEADERS[colIdx + 1].value ===
              Intl.DateTimeFormat('de-DE', { month: 'short' }).format(
                new Date(new Date().setMonth(new Date().getMonth() - 1))
              ),
            isAssignmentStart: Boolean(assignment),
          })
        }
      })
    }

    watch(
      () => selectedYear.value,
      () => {
        createRows()
      }
    )

    watch(() => selectedAssignmentId.value, createRows)

    watch(() => props.person?.targetPerformanceComparison, createRows)

    const { updateWorkingHours } = useUpdateWorkingHours()

    const isEditHourCellMenuOpen = ref(false)

    const cellCoordinate: CellCoordinate = reactive({
      x: null,
      y: null,
    })

    const selectedCell = ref<SelectedCell | null>(null)

    function onClickCell(clickedCell: Cell, event: MouseEvent & { clientX: number; clientY: number }): void {
      if (
        isAssignmentSelected() &&
        (clickedCell.key.startsWith('1') || clickedCell.key.startsWith('0')) &&
        clickedCell.key !== SUM_ROW_IDX_RECORDED_HOURS &&
        clickedCell.key !== SUM_ROW_IDX_PLANNED_HOURS &&
        !isNaN(Number(rowData.value.find((cell) => cell.key === '0-' + getColIdxOfCell(clickedCell))?.value)) &&
        !isNaN(Number(getBudgetOfCertainMonth(getColIdxOfCell(clickedCell) + 1)))
      ) {
        selectedCell.value = {
          cell: { ...clickedCell, value: convertDotIntoCommaInput(Number(clickedCell.value)) },
          type: clickedCell.key.startsWith('0') ? SelectedCellType.PLANNED : SelectedCellType.RECORDED,
        }

        isEditHourCellMenuOpen.value = false

        const assignment = assignments.value.find((assignment) => assignment.id === selectedAssignmentId.value)

        if (!assignment) return

        calcBudget(assignment, getColIdxOfCellAsMonth(clickedCell))

        cellCoordinate.x = event.clientX
        cellCoordinate.y = event.clientY

        root.$nextTick(() => {
          if (hasSufficientRights(Rights.WORKING_HOURS_UPDATE)) {
            isEditHourCellMenuOpen.value = true
          }
        })
      }
    }

    async function onSaveCell(): Promise<void> {
      const cell = rowData.value.find((cell) => cell.key === selectedCell.value?.cell?.key)

      const workingHour = workingHours.value.find((hour) => hour.id === selectedCell.value?.cell?.workingHourId)

      try {
        if (workingHour && selectedCell.value && cell) {
          Object.entries(workingHour).forEach(([key, value]) => value && value.id && (workingHour[key] = value.id))

          await updateWorkingHours(workingHour.id, {
            ...workingHour,
            [`hours${capitalize(selectedCell.value.type)}`]: convertCommaIntoDotInput(
              String(selectedCell.value.cell.value)
            ),
          })

          cell.value = selectedCell.value.cell.value
        }

        addNotification({
          text: root.$t('misc.edited') as string,
          type: 'success',
          timeout: 3000,
        })

        await fetchData()

        const assignment = assignments.value.find((assignment) => assignment.id === selectedAssignmentId.value)
        const cellMonth = Number(cell?.key.split('-')[1])

        assignment && calcBudget(assignment, cellMonth + 1)

        isEditHourCellMenuOpen.value = false
      } catch (error: unknown) {
        handleError(error)
      }
      emit('reload-person')
    }

    async function onResetPlannedHour(): Promise<void> {
      const cell = rowData.value.find((cell) => cell.key === selectedCell.value?.cell?.key)

      const workingHour = workingHours.value.find((hour) => hour.id === selectedCell.value?.cell?.workingHourId)

      try {
        if (workingHour && selectedCell.value && cell) {
          Object.entries(workingHour).forEach(([key, value]) => value && value.id && (workingHour[key] = value.id))

          await updateWorkingHours(workingHour.id, {
            ...workingHour,
            hoursPlanned: -1, // any negative number resets the value
          })

          cell.value = selectedCell.value.cell.value

          addNotification({
            text: root.$t('misc.edited') as string,
            type: 'success',
            timeout: 3000,
          })

          await fetchData()

          const assignment = assignments.value.find((assignment) => assignment.id === selectedAssignmentId.value)
          const cellMonth = getMonthOutOfProperty(cell?.key)

          assignment && cellMonth && calcBudget(assignment, cellMonth + 1)

          isEditHourCellMenuOpen.value = false
        }
      } catch (error: unknown) {
        handleError(error)
      }
      emit('reload-person')
    }

    function getColIdxOfCell(cell: Cell): number {
      return getMonthOutOfProperty(cell?.key) ?? -1
    }

    function getColIdxOfCellAsMonth(cell: Cell): number {
      return getColIdxOfCell(cell) + 1
    }

    function formatValue(cell: Cell): string | number | null {
      if ((cell.key.startsWith('0') || cell.key.startsWith('1')) && !isNaN(Number(cell.value))) {
        return convertDotIntoCommaInput(Number(Number(cell.value).toFixed(1)))
      } else if (cell.key.startsWith('2') && !isNaN(Number(cell.value))) {
        return convertToPercentage(Number(cell.value), 1)
      } else if (
        !isNaN(Number(cell.value)) &&
        (cell.key.startsWith('3') || cell.key.startsWith('4') || cell.key.startsWith('5') || cell.key.startsWith('6'))
      ) {
        const formattedValue = convertToEuro(Number(cell.value))
        return formattedValue ? formattedValue : '-'
      } else {
        return cell.value
      }
    }

    function isEditableCell(cell: Cell): boolean {
      return (
        isAssignmentSelected() &&
        (cell.key.startsWith('1') || cell.key.startsWith('0')) &&
        cell.key !== SUM_ROW_IDX_RECORDED_HOURS &&
        cell.key !== SUM_ROW_IDX_PLANNED_HOURS &&
        !isNaN(Number(rowData.value.find((e) => e.key === '0-' + getColIdxOfCell(cell))?.value)) &&
        !isNaN(Number(getBudgetOfCertainMonth(getColIdxOfCell(cell) + 1)))
      )
    }

    watch(isEditHourCellMenuOpen, (val: boolean) => {
      if (!val && selectedCell.value) {
        selectedCell.value.cell = {} as Cell
      }
    })

    function isPreviousMonthOfCurrentYear(obj: ColumnHeader | Cell): boolean {
      if ('isPreviousMonth' in obj) {
        return obj.isPreviousMonth && new Date().getUTCFullYear() === selectedYear.value
      } else {
        return (
          new Date().getUTCFullYear() === selectedYear.value &&
          obj.value ===
            Intl.DateTimeFormat('de-DE', { month: 'short' }).format(
              new Date(new Date().setMonth(new Date().getMonth() - 1))
            )
        )
      }
    }

    const isFormValid = ref(false)

    const ADDITIONAL_NUMBER_INPUT_RULES = [(value: string) => isRequired(value, 'h')]

    const budgetDataLeft = ref<BudgetDataLeft | null>(null)

    const budgetDataRight = ref<BudgetDataRight | null>(null)

    function getAssignmentByHeader(header: ColumnHeader): AssignmentOutput | undefined {
      const assignment = assignments.value.find((assignment) => {
        const [year, month] = assignment.start.split('-')

        if (
          Number(year) === selectedYear.value &&
          Number(month) === COLUMN_HEADERS.findIndex((column) => column.key === header.key)
        ) {
          return assignment
        }
      })

      return assignment
    }

    function isAssignmentStart(header: ColumnHeader): boolean {
      return Boolean(getAssignmentByHeader(header))
    }

    function getBudgetOfCertainMonth(month: number | undefined): string | number | undefined {
      if (!month) return

      return rowData.value.find((cell) => cell.key === `4-${month - 1}`)?.value
    }

    async function calcBudget(assignment: AssignmentOutput, month: number): Promise<void> {
      if (!assignment?.contracts.length || !month) return

      const newBudgetDataLeft = {} as BudgetDataLeft

      try {
        const contractsOfSelectedAssignment = getContractsOfSelectedAssignment()

        const contractForMonth = getContractForMonth(contractsOfSelectedAssignment, month)

        if (!contractForMonth) return

        newBudgetDataLeft.dailyWorkingTime = contractForMonth.dailyWorkingTime
        newBudgetDataLeft.hourlyRate = contractForMonth.hourlyRate
          ? convertToEuro(contractForMonth.hourlyRate * contractForMonth.dailyWorkingTime)
          : null
        newBudgetDataLeft.banfNr = assignment.banfNr
        newBudgetDataLeft.banfOrderNr = assignment.banfOrderNr
        newBudgetDataLeft.corporateIdentifier = assignment.corporateIdentifier?.name

        budgetDataLeft.value = newBudgetDataLeft
      } catch (error) {
        handleError(error)
      }

      const newBudgetDataRight = {} as BudgetDataRight

      newBudgetDataRight.professionalUnit = assignment.professionalUnit?.name
      newBudgetDataRight.bst = assignment.costCenter?.bst
      newBudgetDataRight.rkost = assignment.costCenter?.rkost
      newBudgetDataRight.pspAccounts = assignment.pspAccounts

      budgetDataRight.value = newBudgetDataRight
    }

    async function onClickMonthHeader(header: ColumnHeader): Promise<void> {
      let assignment: AssignmentOutput | undefined

      if (isAssignmentStart(header) && !isAssignmentSelected()) {
        assignment = getAssignmentByHeader(header)
      } else if (isAssignmentSelected() && !isNaN(Number(getBudgetOfCertainMonth(header.idx)))) {
        assignment = assignments.value.find((assignment) => assignment.id === selectedAssignmentId.value)
      }

      if (!assignment?.contracts.length) return

      calcBudget(assignment, header.idx)
    }

    function shouldRenderPointer(header: ColumnHeader): boolean {
      return (
        (isAssignmentStart(header) || selectedAssignmentId.value > 0) &&
        !isNaN(Number(getBudgetOfCertainMonth(header.idx)))
      )
    }

    return reactive({
      icons: {
        mdiRedoVariant,
      },
      constants: {
        COLUMN_HEADERS,

        ADDITIONAL_NUMBER_INPUT_RULES,
      },
      enums: {
        CellType,
      },
      state: {
        assignments,
        workingHours,

        selectedAssignmentId,
        selectedYear,
        availableYears,

        rowData,

        isEditHourCellMenuOpen,
        cellCoordinate,
        selectedCell,

        isFormValid,

        budgetDataLeft,
        budgetDataRight,
      },
      listeners: {
        onClickCell,
        onSaveCell,
        onResetPlannedHour,

        onClickMonthHeader,
      },
      functions: {
        capitalize,

        formatValue,
        isEditableCell,
        isPreviousMonthOfCurrentYear,

        getAssignmentByHeader,
        getActiveAssignmentsBySelectedYear,
        isAssignmentStart,
        shouldRenderPointer,
      },
    })
  },
})
