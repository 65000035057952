var render = function () {
  var _vm$state$selectedCel, _vm$state$selectedCel2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mx-4 my-4"
  }, [_c('v-row', {
    staticClass: "my-4",
    attrs: {
      "align": "center"
    }
  }, [_c('CommonAutocomplete', {
    staticClass: "flex-grow-0 mr-10",
    attrs: {
      "items": _vm.state.availableYears,
      "small": "",
      "elevation": "0",
      "placeholder": _vm.$t('persons.profile.tabMenu.budget.dropdown.year.placeholder')
    },
    model: {
      value: _vm.state.selectedYear,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "selectedYear", $$v);
      },
      expression: "state.selectedYear"
    }
  }), _c('CommonAutocomplete', {
    staticClass: "flex-grow-0",
    attrs: {
      "items": [{
        id: -1,
        title: 'Alle'
      }].concat(_vm.functions.getActiveAssignmentsBySelectedYear()),
      "item-text": "title",
      "item-value": "id",
      "small": "",
      "elevation": "0",
      "placeholder": _vm.$t('persons.profile.tabMenu.budget.dropdown.assignment.placeholder')
    },
    model: {
      value: _vm.state.selectedAssignmentId,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "selectedAssignmentId", $$v);
      },
      expression: "state.selectedAssignmentId"
    }
  }), _c('div', {
    staticClass: "d-flex ml-auto"
  }, [_vm.state.budgetDataLeft ? _c('BudgetInfoBox', {
    attrs: {
      "budget-data": _vm.state.budgetDataLeft
    }
  }) : _c('v-card', {
    staticClass: "grey lighten-3",
    attrs: {
      "min-width": "400px",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.budget.budgetInfobox.placeholder')) + " ")])], 1), _vm.state.budgetDataRight ? _c('BudgetInfoBox', {
    staticClass: "ml-5",
    attrs: {
      "budget-data": _vm.state.budgetDataRight
    }
  }) : _vm._e()], 1)], 1), _vm.state.rowData.length ? _c('div', {
    staticClass: "grid-container mt-8 position-relative"
  }, [_vm._l(_vm.constants.COLUMN_HEADERS, function (header) {
    return _c('div', {
      key: header.key,
      class: ["".concat(header.key !== 'month' ? 'text-center' : ''), _vm.functions.isAssignmentStart(header) && _vm.state.selectedAssignmentId < 0 ? "grey lighten-3 rounded-t-lg" : '', _vm.functions.shouldRenderPointer(header) ? ' cursor-pointer' : '', 'font-weight-bold grid-line-horizontal py-3 '],
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickMonthHeader(header);
        }
      }
    }, [_vm.functions.isPreviousMonthOfCurrentYear(header) ? _c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('div', {
            staticClass: "d-flex align-center justify-center"
          }, [_vm._v(" " + _vm._s(header.value) + " "), _c('div', _vm._g({
            staticClass: "badge ml-2"
          }, on))])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.budget.hoverTextPrevMonth')) + " ")]) : _c('span', [_vm._v(_vm._s(header.value))])], 1);
  }), _vm._l(_vm.state.rowData, function (cell) {
    return [cell.type === _vm.enums.CellType.NAME_CELL ? _c('div', {
      key: cell.key,
      staticClass: "font-weight-bold py-2 grid-line-horizontal grid-line-vertical"
    }, [_vm._v(" " + _vm._s(cell.value) + " ")]) : _c('div', {
      key: cell.key,
      class: ["text-center py-2 grid-line-horizontal grid-line-vertical", _vm.functions.isEditableCell(cell) && "cursor-pointer grey lighten-3 py-1 px-2"],
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickCell(cell, $event);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.functions.formatValue(cell)) + " "), _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(cell.hasBeenEditedManually ? '*' : ''))])])])];
  }), _c('v-menu', {
    attrs: {
      "position-x": _vm.state.cellCoordinate.x,
      "position-y": _vm.state.cellCoordinate.y,
      "absolute": "",
      "offset-y": "",
      "close-on-content-click": false
    },
    model: {
      value: _vm.state.isEditHourCellMenuOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isEditHourCellMenuOpen", $$v);
      },
      expression: "state.isEditHourCellMenuOpen"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "pb-0"
  }, [_c('p', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("persons.profile.tabMenu.budget.actions.editHours".concat(_vm.functions.capitalize((_vm$state$selectedCel = _vm.state.selectedCell) === null || _vm$state$selectedCel === void 0 ? void 0 : _vm$state$selectedCel.type)))) + " ")])]), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, [(_vm$state$selectedCel2 = _vm.state.selectedCell) !== null && _vm$state$selectedCel2 !== void 0 && _vm$state$selectedCel2.cell ? _c('CommonNumberInput', {
    staticClass: "pt-0 mt-0",
    attrs: {
      "label": "h",
      "rules": _vm.constants.ADDITIONAL_NUMBER_INPUT_RULES,
      "with-autofocus": ""
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.listeners.onSaveCell();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        var _vm$state$selectedCel3, _vm$state$selectedCel4, _vm$state$selectedCel5;

        return [((_vm$state$selectedCel3 = _vm.state.selectedCell) === null || _vm$state$selectedCel3 === void 0 ? void 0 : (_vm$state$selectedCel4 = _vm$state$selectedCel3.cell) === null || _vm$state$selectedCel4 === void 0 ? void 0 : (_vm$state$selectedCel5 = _vm$state$selectedCel4.key) === null || _vm$state$selectedCel5 === void 0 ? void 0 : _vm$state$selectedCel5.split('-')[0]) == 0 ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.listeners.onResetPlannedHour
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiRedoVariant))])], 1)];
            }
          }], null, false, 522613908)
        }, [_vm._v(" " + _vm._s(_vm.$t('form.reset')) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1287111012),
    model: {
      value: _vm.state.selectedCell.cell.value,
      callback: function callback($$v) {
        _vm.$set(_vm.state.selectedCell.cell, "value", $$v);
      },
      expression: "state.selectedCell.cell.value"
    }
  }) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "x-small": "",
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onSaveCell();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.save')) + " ")]), _c('v-btn', {
    staticClass: "ml-auto rounded-lg",
    attrs: {
      "x-small": "",
      "elevation": "0",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isEditHourCellMenuOpen = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.cancel')) + " ")])], 1)], 1)], 1)], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }