import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  NewWorkingHours,
  UseCreateWorkingHours,
  UseGetWorkingHours,
  UseUpdateWorkingHours,
  UseDeleteWorkingHours,
  WorkingHours,
  WorkingHoursId,
} from '@/api/types/workingHours'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'working-hours'

function useGetWorkingHours(): UseGetWorkingHours {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetWorkingHours['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useCreateWorkingHours(): UseCreateWorkingHours {
  const axios = useAxios<PromiseType<ReturnType<UseCreateWorkingHours['createWorkingHours']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createWorkingHours(data: NewWorkingHours): Promise<WorkingHours> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createWorkingHours,
  }
}

function useUpdateWorkingHours(): UseUpdateWorkingHours {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateWorkingHours['updateWorkingHours']>>>({ method: 'PUT' })

  function updateWorkingHours(id: WorkingHoursId, data: WorkingHours): Promise<WorkingHours> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateWorkingHours,
  }
}

function useDeleteWorkingHours(): UseDeleteWorkingHours {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteWorkingHours['deleteWorkingHours']>>>({ method: 'DELETE' })

  function deleteWorkingHours(id: WorkingHoursId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteWorkingHours,
  }
}

export { useGetWorkingHours, useCreateWorkingHours, useUpdateWorkingHours, useDeleteWorkingHours }
